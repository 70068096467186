import { useCallback, useMemo } from 'react'
import { gql, useMutation } from '@apollo/client'
import { ReportUserStoryMutation } from '@/types/codegen-federation'

const REPORT_STORY_BY_ID = gql`
  mutation reportUserStory($input: ReportUserStoryInput!) {
    reportUserStory(reportUserStoryInput: $input) {
      id
      storyId
    }
  }
`

const TOGGLE_LIKE_USER_STORY_MUTATION = gql`
  mutation toggleLikeUserStoryById($storyId: String!) {
    toggleLikeUserStory(storyId: $storyId) {
      id
      isLikedByUser
      totalLikes
    }
  }
`

export const useReportUserStoryById = () => {
  const [reportStoryByIdMutation, { loading }] = useMutation<ReportUserStoryMutation>(REPORT_STORY_BY_ID, {
    errorPolicy: 'all',
  })

  const reportUserStoryById = useCallback(
    async (storyId: string) => {
      return await reportStoryByIdMutation({
        variables: {
          input: {
            storyId,
          },
        },
      })
    },
    [reportStoryByIdMutation],
  )

  return useMemo(
    () => ({
      reportUserStoryById,
      isLoading: loading,
    }),
    [loading, reportUserStoryById],
  )
}

export const useToggleLikeUserStoryById = () => {
  const [toggleLikeUserStoryMutation, { data, loading }] = useMutation(TOGGLE_LIKE_USER_STORY_MUTATION, {
    errorPolicy: 'all',
  })

  const toggleLikeUserStoryById = useCallback(
    (storyId: string) => {
      return toggleLikeUserStoryMutation({
        variables: {
          storyId,
        },
      })
    },
    [toggleLikeUserStoryMutation],
  )

  return useMemo(
    () => ({
      toggleLikeUserStoryById,
      toggleLikeUserStoryResult: data,
      isLoading: loading,
    }),
    [data, loading, toggleLikeUserStoryById],
  )
}
